import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ApiService from "../../api";
import CrudDetail from "../../Components/CrudDetail";
import Input from "../../Components/Input";
import TimestampComponent from "../../Components/TimestampComponent";
import PageContainer from "../../Layout/PageContainer";
import { Customer } from "../../Types/Customer";
import { Event } from "../../Types/Event";
import { resolveEventDescription } from "./resolveEventDetails";

export default function EventDetail() {
  const { id } = useParams();
  const [event, setEvent] = useState<Event | null | undefined>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);

  const populate = async () => {
    console.log("populate");
    if (!id) {
      setEvent(undefined);
      return;
    }

    const event = await ApiService.getInstance().getEventById(parseInt(id));
    if (!event) {
      setEvent(undefined);
      return;
    }

    setEvent(event.data);
    if (event.data.customer) {
      setCustomer(event.data.customer);
    } else if (event.data.data.telegramUserId) {
      const c = await ApiService.getInstance().getCustomerByTelegramUserId(
        event.data.data.telegramUserId.toString(),
      );
      if (c && c.data) {
        setCustomer(c.data);
      }
    }
  };

  const memoizedPopulate = useCallback(populate, [id]);

  useEffect(() => {
    memoizedPopulate();
  }, [id, memoizedPopulate]);

  return (
    <PageContainer title={`${event?.type}`}>
      <CrudDetail
        title={event?.type ?? ""}
        subTitle={resolveEventDescription(event?.type ?? "CommandExecuted")}
        loading={event === null}
        onSubmit={() => {}}
        asDiv
        failedFetch={event === undefined}
      >
        {event && <TimestampComponent unformattedDate={event.timestamp} />}
        <CrudDetail
          title="Command Collected Information"
          subTitle="This information was collected throughout the command"
          onSubmit={() => {}}
          asDiv
        >
          <div className="grid grid-cols-1 gap-x-6 gap-y-8">
            {event?.data.message && (
              <Input
                name="message"
                type="text"
                label="Message"
                value={event.data.message}
                readonly
              />
            )}
            {event?.data.inputEmail && (
              <Input
                name="inputEmail"
                type="text"
                label="Input Email"
                value={event.data.inputEmail}
                readonly
              />
            )}
            {event?.data.telegramUserId && (
              <Input
                name="telegramUserId"
                type="text"
                label="Telegram User Id"
                value={event.data.telegramUserId}
                readonly
              />
            )}
            {event?.data.groupName && (
              <Input
                name="telegramGroupName"
                type="text"
                label="Telegram Group Name"
                value={event.data.groupName}
                readonly
              />
            )}
            {event?.data.command && (
              <Input
                name="command"
                type="text"
                label="Command Name"
                value={event.data.command}
                readonly
              />
            )}
            {event?.data.newEmail && (
              <Input
                name="newEmail"
                type="text"
                label="New E-Mail"
                value={event.data.newEmail}
                readonly
              />
            )}
            {event?.data.oldEmail && (
              <Input
                name="oldEmail"
                type="text"
                label="Old E-Mail"
                value={event.data.oldEmail}
                readonly
              />
            )}

            {event?.data.telegramUserName && (
              <Input
                name="telegramUserName"
                type="text"
                label="Telegram User Name"
                value={event?.data.telegramUserName}
                readonly
                containerClass="sm:col-span-3"
              />
            )}

            {event?.data.telegramUserHandle && (
              <Input
                name="telegramUserHandle"
                type="text"
                label="Telegram User Handle"
                value={event?.data.telegramUserHandle}
                readonly
                containerClass="sm:col-span-3"
              />
            )}
          </div>
        </CrudDetail>
        {!customer && (
          <CrudDetail
            title="Contact Info"
            subTitle="Contact was not found"
            asDiv
            onSubmit={() => {}}
          >
            <></>
          </CrudDetail>
        )}

        {customer && (
          <CrudDetail
            title="Contact Info"
            subTitle="Short contact overview"
            asDiv
            onSubmit={() => {}}
          >
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <Input
                name="firstName"
                type="text"
                label="First Name"
                value={customer?.firstName}
                readonly
                containerClass="sm:col-span-3"
              />
              <Input
                name="lastName"
                type="text"
                label="Last Name"
                value={customer?.lastName}
                readonly
                containerClass="sm:col-span-3"
              />
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <Input
                name="email"
                type="email"
                label="E-Mail"
                value={customer?.email}
                readonly
                containerClass="sm:col-span-3"
              />
              <Input
                name="stripeFullName"
                type="text"
                label="Stripe Full Name"
                value={customer?.stripeFullName}
                readonly
                containerClass="sm:col-span-3"
              />
            </div>

            <Link
              to={`/contacts/${customer.id}`}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go to Profile
            </Link>
          </CrudDetail>
        )}
      </CrudDetail>
    </PageContainer>
  );
}
