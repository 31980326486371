import Layout from "./Layout/Layout";
import Dashboard from "./Pages/Dashboard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Groups from "./Pages/Groups/Groups";
import Contacts from "./Pages/Contacts/Contacts";
import Events from "./Pages/Events/Events";
import NoPage from "./Pages/NoPage";
import ContactDetail from "./Pages/Contacts/ContactDetail";
import GroupDetail from "./Pages/Groups/GroupDetail";
import GroupCreate from "./Pages/Groups/GroupCreate";
import Bot from "./Pages/Bot/Bot";
import CommandDetail from "./Pages/Bot/CommandDetail";
import CommandCreate from "./Pages/Bot/CommandCreate";
import EventDetail from "./Pages/Events/EventDetail";
import Login from "./Pages/Login";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" index element={<Login />} />

        <Route
          path="/"
          index
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />

        <Route
          path="/groups"
          element={
            <Layout>
              <Groups />
            </Layout>
          }
        />
        <Route
          path="/groups/create"
          element={
            <Layout>
              <GroupCreate />
            </Layout>
          }
        />
        <Route
          path="/groups/:id"
          element={
            <Layout>
              <GroupDetail />
            </Layout>
          }
        />

        <Route
          path="/contacts"
          element={
            <Layout>
              <Contacts />
            </Layout>
          }
        />
        <Route
          path="/contacts/:id"
          element={
            <Layout>
              <ContactDetail />
            </Layout>
          }
        />

        <Route
          path="/events"
          element={
            <Layout>
              <Events />
            </Layout>
          }
        />
        <Route
          path="/events/:id"
          element={
            <Layout>
              <EventDetail />
            </Layout>
          }
        />

        <Route
          path="/bot"
          element={
            <Layout>
              <Bot />
            </Layout>
          }
        />
        <Route
          path="/commands/:id"
          element={
            <Layout>
              <CommandDetail />
            </Layout>
          }
        />
        <Route
          path="/commands/create"
          element={
            <Layout>
              <CommandCreate />
            </Layout>
          }
        />

        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
  );
}

export default App;
