import PageContainer from "../../Layout/PageContainer";
import { useEffect, useState } from "react";
import { Customer, CustomerIndexResponse } from "../../Types/Customer";
import ApiService from "../../api";
import CrudContainerWithSearchbar from "../../Components/CrudContainer";
import CrudList, { CrudListItem } from "../../Components/CrudList";
import Pagination from "../../Components/Pagination";

function Contacts() {
  const [customerIndexRespones, setCustomerIndexResponse] =
    useState<CustomerIndexResponse | null>(null);
  const [allCustomers, setAllCustomers] = useState<
    Customer[] | null | undefined
  >(null);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[] | null>(
    null,
  );

  const filter = (key: string) => {
    if (!allCustomers) {
      return;
    }
    populateWithEmail(0, 15, key);
  };

  const populateWithEmail = async (
    skip: number = 0,
    take: number = 15,
    email: string,
  ) => {
    const customers = await ApiService.getInstance().getAllCustomers(
      skip,
      take,
      email,
    );
    if (!customers) {
      setAllCustomers(undefined);
      return;
    }
    setAllCustomers(customers.data.customers);
    setFilteredCustomers(customers.data.customers);
    setCustomerIndexResponse(customers.data);
  };

  const populate = async (skip: number = 0, take: number = 15) => {
    const customers = await ApiService.getInstance().getAllCustomers(
      skip,
      take,
    );
    if (!customers) {
      setAllCustomers(undefined);
      return;
    }
    setAllCustomers(customers.data.customers);
    setFilteredCustomers(customers.data.customers);
    setCustomerIndexResponse(customers.data);
  };

  useEffect(() => {
    populate();
  }, []);

  return (
    <PageContainer title="Contacts">
      <CrudContainerWithSearchbar
        searchCallback={filter}
        searchInputName="searchContactByEmail"
        searchHint="Search contact by email"
        failedToFetch={allCustomers === undefined}
        loading={allCustomers === null}
        noDataFound={
          filteredCustomers !== null &&
          filteredCustomers !== undefined &&
          filteredCustomers.length <= 0
        }
      >
        {filteredCustomers && filteredCustomers.length > 0 && (
          <CrudList>
            {filteredCustomers.map((customer) => (
              <CrudListItem
                key={customer.id}
                itemDetailsLink={`/contacts/${customer.id}`}
                itemName={customer.email}
                itemSubInfo={`${customer.firstName} ${customer.lastName}`}
              />
            ))}
          </CrudList>
        )}
      </CrudContainerWithSearchbar>
      <Pagination
        skip={customerIndexRespones?.skip ?? 0}
        take={customerIndexRespones?.take ?? 0}
        total={customerIndexRespones?.total ?? 0}
        populateCallback={populate}
      />
    </PageContainer>
  );
}

export default Contacts;
