import axios, { AxiosInstance, isAxiosError } from "axios";
import { Customer, CustomerIndexResponse } from "./Types/Customer";
import { Event, EventIndexResponse } from "./Types/Event";
import { TelegramBotCommand } from "./Types/TelegramBotCommand";
import { TelegramGroup } from "./Types/TelegramGroup";
import { TelegramGroupAccess } from "./Types/TelegramGroupAccess";

export type LoginResponse = {
  token: string;
};

export type DashboardStats = {
  accessProvidedCount: number;
  groupCount: number;
  contactCount: number;
  eventCount: number;
};

export class PositiveResponse<DataType> {
  constructor(
    public status: number,
    public message: string,
    public data: DataType,
  ) {}
}

export class NegativeResponse {
  constructor(
    public message: string | string[],
    public status?: number,
    public statusCode?: number,
    public error?: string,
  ) {}
}

export default class ApiService {
  private static instance: ApiService;
  private axiosInstance: AxiosInstance;

  private constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 1000,
      headers: {
        Accept: "application/json",
      },
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response?.status === 401 &&
          !window.location.href.includes("/login")
        ) {
          window.location.href = "/login";
        }
        return Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.request.use(
      (request) => {
        const token = localStorage.getItem("token");
        if (token) {
          request.headers.Authorization = `Bearer ${token}`;
        }
        return request;
      },
      (error) => error,
    );
  }

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }

    return ApiService.instance;
  }

  public async login(
    email: string,
    password: string,
  ): Promise<LoginResponse | null> {
    try {
      const resp = await this.axiosInstance.post<LoginResponse>("/login", {
        email: email,
        password: password,
      });
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async getAllEvents(
    skip: number,
    take: number,
  ): Promise<PositiveResponse<EventIndexResponse> | null> {
    try {
      const resp = await this.axiosInstance.get<
        PositiveResponse<EventIndexResponse>
      >(`/events?skip=${skip}&take=${take}`);
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async getEventById(
    id: number,
  ): Promise<PositiveResponse<Event> | null> {
    try {
      const resp = await this.axiosInstance.get<PositiveResponse<Event>>(
        `/events/${id}`,
      );
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async getAllCustomers(
    skip: number,
    take: number,
    email: string = "",
  ): Promise<PositiveResponse<CustomerIndexResponse> | null> {
    try {
      const resp = await this.axiosInstance.get<
        PositiveResponse<CustomerIndexResponse>
      >(`/customer?skip=${skip}&take=${take}&email=${email}`);
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async getCustomerById(
    id: number,
  ): Promise<PositiveResponse<Customer> | null> {
    try {
      const resp = await this.axiosInstance.get<PositiveResponse<Customer>>(
        `/customer/${id}`,
      );
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async getCustomerByTelegramUserId(
    id: string,
  ): Promise<PositiveResponse<Customer> | null> {
    try {
      const resp = await this.axiosInstance.get<PositiveResponse<Customer>>(
        `/customer/telegram/${id}`,
      );
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async getAllTelegramGroups(): Promise<PositiveResponse<
    TelegramGroup[]
  > | null> {
    try {
      const resp =
        await this.axiosInstance.get<PositiveResponse<TelegramGroup[]>>(
          `/telegramgroups`,
        );
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async getTelegramGroupById(
    id: number,
  ): Promise<PositiveResponse<TelegramGroup> | null> {
    try {
      const resp = await this.axiosInstance.get<
        PositiveResponse<TelegramGroup>
      >(`/telegramgroups/${id}`);
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async createTelegramGroup(
    groupName: string,
    groupId: number,
  ): Promise<PositiveResponse<TelegramGroup> | NegativeResponse> {
    try {
      const resp = await this.axiosInstance.post<
        PositiveResponse<TelegramGroup> | NegativeResponse
      >("/telegramgroups", {
        telegramGroupName: groupName,
        telegramGroupId: groupId,
      });
      return resp.data;
    } catch (error) {
      if (isAxiosError(error)) {
        return error.response?.data as NegativeResponse;
      }
    }
    return { message: "Creation failed", status: 400 };
  }

  public async updateTelegramGroupAccess(
    updated: TelegramGroupAccess,
  ): Promise<PositiveResponse<TelegramGroupAccess> | NegativeResponse> {
    try {
      const resp = await this.axiosInstance.put<
        PositiveResponse<TelegramGroup> | NegativeResponse
      >("/telegramgroupaccess", updated);
      return resp.data;
    } catch (error) {
      if (isAxiosError(error)) {
        return error.response?.data as NegativeResponse;
      }
    }
    return { message: "Update failed", status: 400 };
  }

  public async getAllTelegramBotCommands(): Promise<PositiveResponse<
    TelegramBotCommand[]
  > | null> {
    try {
      const resp = await this.axiosInstance.get<
        PositiveResponse<TelegramBotCommand[]>
      >("/telegrambotcommands");
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async deleteTelegramBotCommand(
    id: number,
  ): Promise<PositiveResponse<TelegramBotCommand> | NegativeResponse> {
    try {
      const resp = await this.axiosInstance.delete<
        PositiveResponse<TelegramBotCommand> | NegativeResponse
      >(`/telegrambotcommands/${id}`);
      return resp.data;
    } catch (error) {
      if (isAxiosError(error)) {
        return error.response?.data as NegativeResponse;
      }
    }
    return { message: "Delete failed", status: 400 };
  }

  public async updateTelegramBotCommand(
    updated: TelegramBotCommand,
  ): Promise<PositiveResponse<TelegramBotCommand> | NegativeResponse> {
    try {
      const resp = await this.axiosInstance.put<
        PositiveResponse<TelegramBotCommand> | NegativeResponse
      >("/telegrambotcommands", updated);
      return resp.data;
    } catch (error) {
      if (isAxiosError(error)) {
        return error.response?.data as NegativeResponse;
      }
    }
    return { message: "Update failed", status: 400 };
  }

  public async createTelegramBotCommand(
    command: string,
    outputType: string,
    commandOutput: string,
  ): Promise<PositiveResponse<TelegramBotCommand> | NegativeResponse> {
    try {
      const resp = await this.axiosInstance.post<
        PositiveResponse<TelegramBotCommand> | NegativeResponse
      >("/telegrambotcommands", {
        command: command,
        outputType: outputType,
        commandOutput: commandOutput,
      });
      return resp.data;
    } catch (error) {
      if (isAxiosError(error)) {
        return error.response?.data as NegativeResponse;
      }
    }
    return { message: "Creation failed", status: 400 };
  }

  public async getTelegramBotCommandById(
    id: number,
  ): Promise<PositiveResponse<TelegramBotCommand> | null> {
    try {
      const resp = await this.axiosInstance.get<
        PositiveResponse<TelegramBotCommand>
      >(`/telegrambotcommands/${id}`);
      return resp.data;
    } catch (error) {
      return null;
    }
  }

  public async getDashboardStats(): Promise<PositiveResponse<DashboardStats> | null> {
    try {
      const resp =
        await this.axiosInstance.get<PositiveResponse<DashboardStats>>(
          "/dashboard",
        );
      return resp.data;
    } catch (error) {
      return null;
    }
  }
}
