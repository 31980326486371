import { ReactNode } from "react";
import { Link } from "react-router-dom";

type CrudContainerWithSearchbarProps = {
  searchHint: string;
  searchInputName: string;
  searchCallback: (args0: string) => void;
  children: ReactNode;
  failedToFetch: boolean;
  noDataFound: boolean;
  loading?: boolean;
  createNewLink?: string;
};

function CrudContainerWithSearchbar({
  searchHint,
  searchInputName,
  searchCallback,
  children,
  failedToFetch,
  noDataFound,
  createNewLink,
  loading,
}: CrudContainerWithSearchbarProps) {
  return (
    <div className="mt-2">
      <div className="flex gap-3">
        <div className="flex-grow flex-shrink w-0 flex-1">
          <label
            htmlFor={searchInputName}
            className="block text-sm font-medium leading-6 text-gray-900 text-center"
          >
            {searchHint}
          </label>
          <div className="mt-2 text-center flex justify-between gap-x-3">
            <input
              onChange={(e) => searchCallback(e.target.value)}
              type="text"
              name={searchInputName}
              id={searchInputName}
              className="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="..."
            />
            {createNewLink && (
              <Link
                to={createNewLink}
                className="self-end flex-none w-1/10 inline-block rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white hover:bg-opacity-75"
              >
                Create New
              </Link>
            )}
          </div>
        </div>
      </div>

      {loading && <h3 className="text-center mt-2">Loading...</h3>}

      {failedToFetch && (
        <h3 className="text-center mt-2">Failed to fetch data</h3>
      )}

      {noDataFound && <h3 className="text-center mt-2">Nothing found</h3>}

      {!failedToFetch && !noDataFound && children}
    </div>
  );
}

export default CrudContainerWithSearchbar;
