import PageContainer from "../../Layout/PageContainer";
import { useEffect, useState } from "react";
import { TelegramGroup } from "../../Types/TelegramGroup";
import ApiService from "../../api";
import CrudContainerWithSearchbar from "../../Components/CrudContainer";
import CrudList, { CrudListItem } from "../../Components/CrudList";

function Groups() {
  const [allGroups, setAllGroups] = useState<
    TelegramGroup[] | null | undefined
  >(null);
  const [filteredGroups, setFilteredGroups] = useState<TelegramGroup[] | null>(
    null,
  );

  useEffect(() => {
    async function populate() {
      const r = await ApiService.getInstance().getAllTelegramGroups();
      if (!r) {
        setAllGroups(undefined);
        return;
      }
      setAllGroups(r.data);
      setFilteredGroups(r.data);
    }
    populate();
  }, []);

  const filter = (key: string) => {
    if (!allGroups) {
      return;
    }
    const filtered = allGroups.filter((e) =>
      e.telegramGroupName.toLowerCase().includes(key.toLowerCase()),
    );
    setFilteredGroups(filtered);
  };

  return (
    <PageContainer title="Groups">
      <CrudContainerWithSearchbar
        searchCallback={filter}
        searchInputName="searchGroupByName"
        searchHint="Search group by name"
        failedToFetch={allGroups === undefined}
        loading={allGroups === null}
        noDataFound={
          filteredGroups !== null &&
          filteredGroups !== undefined &&
          filteredGroups.length <= 0
        }
        createNewLink="/groups/create"
      >
        {filteredGroups && filteredGroups.length > 0 && (
          <CrudList>
            {filteredGroups.map((group) => (
              <CrudListItem
                key={group.id}
                itemDetailsLink={`/groups/${group.id}`}
                itemName={group.telegramGroupName}
                itemSubInfo={`${group.telegramGroupId}`}
              />
            ))}
          </CrudList>
        )}
      </CrudContainerWithSearchbar>
    </PageContainer>
  );
}

export default Groups;
