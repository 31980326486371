import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../Layout/PageContainer";
import { useForm } from "react-hook-form";
import ApiService from "../../api";
import CrudDetail from "../../Components/CrudDetail";
import Input from "../../Components/Input";

function GroupCreate() {
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<string[]>([]);

  const onSubmit = async (data: any) => {
    const groupName = data.groupName;
    const groupId = data.groupId;

    const createResp = await ApiService.getInstance().createTelegramGroup(
      groupName,
      groupId,
    );

    if (!createResp) {
      setErrors(["Unknown errors, please retry"]);
      return;
    }

    if (createResp.message && Array.isArray(createResp.message)) {
      setErrors(createResp.message);
      return;
    }

    if ("data" in createResp) {
      navigate(`/groups/${createResp.data.id}`);
    }
  };

  return (
    <PageContainer title="Create new group">
      <CrudDetail
        title="Group Information"
        submitText="Create"
        subTitle="This information cannot be changed after."
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid gap-y-8">
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-x-6 gap-y-8">
            <Input
              containerClass="sm:col-span-3"
              register={register}
              name="groupName"
              type="text"
              label="Group Name"
            />
            <Input
              containerClass="sm:col-span-3"
              register={register}
              name="groupId"
              type="number"
              label="Group Id"
            />
          </div>
        </div>
      </CrudDetail>
    </PageContainer>
  );
}

export default GroupCreate;
