import { useEffect, useState } from "react";
import ApiService, { DashboardStats } from "../api";
import PageContainer from "../Layout/PageContainer";

export default function Dashboard() {
  const [dashboardStats, setDashboardStats] = useState<
    DashboardStats | null | undefined
  >(null);

  useEffect(() => {
    async function pop() {
      const resp = await ApiService.getInstance().getDashboardStats();
      if (!resp) {
        return;
      }

      setDashboardStats(resp.data);
    }
    pop();
  }, []);

  return (
    <PageContainer title="Dashboard">
      <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600">
            Invited Members Amount
          </dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
            {dashboardStats?.accessProvidedCount}
          </dd>
        </div>
        <div className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600">
            Total Groups
          </dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
            {dashboardStats?.groupCount}
          </dd>
        </div>
        <div className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600">
            Total Contacts
          </dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
            {dashboardStats?.contactCount}
          </dd>
        </div>
        <div className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600">
            Total Events
          </dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
            {dashboardStats?.eventCount}
          </dd>
        </div>
      </dl>
    </PageContainer>
  );
}

Dashboard.Amount = {};
