type Props = {
  unformattedDate: string;
};

function TimestampComponent({ unformattedDate }: Props) {
  const formattedDate = () => {
    const date = new Date(unformattedDate);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <div className="flex items-center justify-center bg-gray-100 p-4 rounded-lg shadow-md">
      <div className="flex items-center space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-blue-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
          />
        </svg>
        <span className="text-lg font-semibold text-gray-700">
          {formattedDate()}
        </span>
      </div>
    </div>
  );
}

export default TimestampComponent;
