import PageContainer from "../Layout/PageContainer";

function NoPage() {
  return (
    <PageContainer title="404 - Not Found">
      <span />
    </PageContainer>
  );
}

export default NoPage;
