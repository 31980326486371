import { ReactNode } from "react";

type PageContainerProps = {
  title: string;
  children: ReactNode;
};

function PageContainer({ title, children }: PageContainerProps) {
  return (
    <div className="bg-white py-14 sm:py-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl break-words break-all">
              {title}
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-600">
              FightGecko Telegram Community Management
            </p>
          </div>
          <hr className="w-full bg-black m-2" />
          {children}
        </div>
      </div>
    </div>
  );
}

export default PageContainer;
