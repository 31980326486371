import { ReactNode } from "react";
import { Link } from "react-router-dom";

type CrudListProps = {
  children: ReactNode;
};

function CrudList({ children }: CrudListProps) {
  return <ul className="divide-y divide-gray-100">{children}</ul>;
}

type CrudListItemProps = {
  itemName: string;
  itemSubInfo: string;
  itemDetailsLink: string;
};

export function CrudListItem({
  itemName,
  itemSubInfo,
  itemDetailsLink,
}: CrudListItemProps) {
  return (
    <li key={itemName} className="flex justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4">
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            {itemName}
          </p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            {itemSubInfo}
          </p>
        </div>
      </div>
      <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
        <Link
          to={itemDetailsLink}
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Details
        </Link>
      </div>
    </li>
  );
}

export default CrudList;
