type ErrorComponentProps = {
  errors?: string[];
};

export default function ErrorComponent({ errors }: ErrorComponentProps) {
  if (!errors || errors.length <= 0) {
    return <></>;
  }

  return (
    <div className="mt-2">
      {errors.map((e) => {
        return (
          <p key={e} className="text-red-500">
            {e}
          </p>
        );
      })}
    </div>
  );
}
