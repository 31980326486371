type PaginationProps = {
  skip: number;
  take: number;
  total: number;
  populateCallback: (skip: number, take: number) => void;
};

export default function Pagination({
  skip,
  take,
  total,
  populateCallback,
}: PaginationProps) {
  const prevEnabled = skip > 0;
  const nextEnabled = skip + take < total;

  return (
    <div className="flex flex-row gap-x-3 justify-center mt-4">
      <button
        onClick={() => populateCallback(Math.max(0, skip - take), take)}
        disabled={!prevEnabled}
        className="w-24 rounded-md disabled:bg-gray-200 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Previous
      </button>
      <button
        onClick={() => populateCallback(skip + take, take)}
        disabled={!nextEnabled}
        className="w-24 rounded-md disabled:bg-gray-200 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Next
      </button>
    </div>
  );
}
