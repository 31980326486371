import PageContainer from "../../Layout/PageContainer";
import { useEffect, useState } from "react";
import { TelegramBotCommand } from "../../Types/TelegramBotCommand";
import ApiService from "../../api";
import CrudContainerWithSearchbar from "../../Components/CrudContainer";
import CrudList, { CrudListItem } from "../../Components/CrudList";

function Bot() {
  const [allTelegramBotCommands, setAllTelegramBotCommands] = useState<
    TelegramBotCommand[] | null | undefined
  >(null);
  const [filteredTelegramBotCommands, setFilteredTelegramBotCommands] =
    useState<TelegramBotCommand[] | null>(null);

  const filter = (key: string) => {
    if (!allTelegramBotCommands) {
      return;
    }
    const filtered = allTelegramBotCommands.filter((e) =>
      e.command.toLowerCase().includes(key.toLowerCase()),
    );
    setFilteredTelegramBotCommands(filtered);
  };

  useEffect(() => {
    async function populate() {
      const commands =
        await ApiService.getInstance().getAllTelegramBotCommands();
      if (!commands) {
        setAllTelegramBotCommands(undefined);
        return;
      }
      setAllTelegramBotCommands(commands.data);
      setFilteredTelegramBotCommands(commands.data);
    }
    populate();
  }, []);

  return (
    <PageContainer title="Bot Management">
      <CrudContainerWithSearchbar
        searchCallback={filter}
        searchInputName="searchCommandByName"
        searchHint="Search command by name"
        failedToFetch={allTelegramBotCommands === undefined}
        loading={allTelegramBotCommands === null}
        noDataFound={
          filteredTelegramBotCommands !== null &&
          filteredTelegramBotCommands !== undefined &&
          filteredTelegramBotCommands.length <= 0
        }
        createNewLink="/commands/create"
      >
        {filteredTelegramBotCommands &&
          filteredTelegramBotCommands.length > 0 && (
            <CrudList>
              {filteredTelegramBotCommands.map((command) => (
                <CrudListItem
                  key={command.id}
                  itemDetailsLink={`/commands/${command.id}`}
                  itemName={command.command}
                  itemSubInfo={`${command.id} - ${command.outputType}`}
                />
              ))}
            </CrudList>
          )}
      </CrudContainerWithSearchbar>
    </PageContainer>
  );
}

export default Bot;
