import { FormEvent, ReactNode } from "react";
import ErrorComponent from "./ErrorComponent";

type CrudDetailProps = {
  title: string;
  subTitle: string;
  submitText?: string;
  deleteText?: string;
  deleteCallback?: (e: FormEvent) => void;
  children: ReactNode;
  onSubmit: (args0: any) => void;
  errors?: string[];
  loading?: boolean;
  failedFetch?: boolean;
  redTitle?: string;
  containerCssClass?: string;
  asDiv?: boolean;
};

export default function CrudDetail({
  title,
  subTitle,
  submitText,
  children,
  onSubmit,
  errors,
  loading,
  failedFetch,
  redTitle,
  deleteText,
  deleteCallback,
  containerCssClass,
  asDiv,
}: CrudDetailProps) {
  type ContainerProps = {
    children: ReactNode;
  };

  const ThisContainer = ({ children }: ContainerProps) => {
    if (asDiv) {
      return (
        <div
          className={`${containerCssClass ? containerCssClass : "mt-4"}`}
          onSubmit={onSubmit}
        >
          {children}
        </div>
      );
    }

    return (
      <form
        className={`${containerCssClass ? containerCssClass : "mt-4"}`}
        onSubmit={onSubmit}
      >
        {children}
      </form>
    );
  };

  return (
    <ThisContainer>
      <div className="space-y-12">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {title}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{subTitle}</p>
          <p className="mt-1 text-sm leading-6 text-red-500">{redTitle}</p>

          {failedFetch && (
            <h3 className="text-center mt-2">Failed to load data</h3>
          )}
          {loading && <h3 className="text-center mt-2">Loading...</h3>}
          {!loading && !failedFetch && (
            <div className="mt-10 grid gap-y-8">{children}</div>
          )}
        </div>
      </div>
      {!loading && !failedFetch && <ErrorComponent errors={errors} />}
      {!loading &&
        !failedFetch &&
        (submitText !== undefined || deleteText !== undefined) && (
          <div className="mt-2 flex sm:items-end flex-row justify-end gap-x-3">
            {submitText !== undefined && (
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {submitText}
              </button>
            )}
            {deleteText !== undefined && (
              <button
                className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={deleteCallback}
              >
                {deleteText}
              </button>
            )}
          </div>
        )}
    </ThisContainer>
  );
}
