import { useNavigate, useParams } from "react-router-dom";
import { FormEvent, useCallback, useEffect, useState } from "react";
import PageContainer from "../../Layout/PageContainer";
import { TelegramBotCommand } from "../../Types/TelegramBotCommand";
import ApiService from "../../api";
import CrudDetail from "../../Components/CrudDetail";
import Input from "../../Components/Input";
import Textarea from "../../Components/Textarea";
import { useForm } from "react-hook-form";

function CommandDetail() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [errors, setErrors] = useState<string[]>([]);

  const { handleSubmit, register } = useForm();

  const onSubmit = async (data: any) => {
    if (!telegramBotCommand) {
      return;
    }

    const resp = await ApiService.getInstance().updateTelegramBotCommand({
      id: telegramBotCommand.id,
      commandOutput: data.commandOutput,
      outputType: data.outputType,
      command: data.command,
    });

    if (resp.message && Array.isArray(resp.message)) {
      setErrors(resp.message);
      return;
    }
    setErrors([]);
    populate();
  };

  const onDelete = async (e: FormEvent) => {
    e.preventDefault();
    if (!telegramBotCommand) {
      return;
    }

    const resp = await ApiService.getInstance().deleteTelegramBotCommand(
      telegramBotCommand.id,
    );

    if (resp.status === 200) {
      navigate("/bot");
    }
  };

  const [telegramBotCommand, setTelegramBotCommand] = useState<
    TelegramBotCommand | null | undefined
  >(null);

  const populate = async () => {
    if (!id) {
      setTelegramBotCommand(undefined);
      return;
    }

    const telegramBotCommand =
      await ApiService.getInstance().getTelegramBotCommandById(parseInt(id));

    if (!telegramBotCommand) {
      setTelegramBotCommand(undefined);
      return;
    }

    setTelegramBotCommand(telegramBotCommand.data);
  };

  const memoizedPopulate = useCallback(populate, [id]);

  useEffect(() => {
    async function run() {
      await memoizedPopulate();
    }
    run();
  }, [id, memoizedPopulate]);

  return (
    <PageContainer title={telegramBotCommand?.command ?? ""}>
      <CrudDetail
        title="Command Information"
        subTitle="This information was manually input. The command can be used by users in the telegram bot."
        onSubmit={handleSubmit(onSubmit)}
        errors={errors}
        failedFetch={telegramBotCommand === undefined}
        loading={telegramBotCommand === null}
        submitText="Save"
        deleteText="Delete"
        deleteCallback={onDelete}
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <Input
            name="command"
            type="text"
            label="Command"
            containerClass="sm:col-span-3"
            value={telegramBotCommand?.command}
            validation={{ required: true }}
            register={register}
          />
          <Input
            name="outputType"
            type="text"
            label="Output Type"
            containerClass="sm:col-span-3"
            value={telegramBotCommand?.outputType}
            validation={{ required: true }}
            register={register}
          />
        </div>
        <Textarea
          name="commandOutput"
          label="Command Output"
          value={telegramBotCommand?.commandOutput}
          register={register}
        />
      </CrudDetail>
    </PageContainer>
  );
}

export default CommandDetail;
