import { EventType } from "../../Types/Event";

export function resolveEventDescription(type: EventType) {
  if (type === "CommandExecuted") {
    return "A Telegram Bot command has been executed by a user.";
  }

  if (type === "FailedToSendMessage") {
    return "The Telegram Bot failed to send a message to a user.";
  }

  if (type === "GroupAccessRevoked") {
    return "The automation has revoked the group access of a user and kicked them.";
  }

  if (type === "GroupAccessRequested") {
    return "A user has requested access to the telegram groups using the Telegram Bot";
  }

  if (type === "GroupAccessProvided") {
    return "The Telegram Bot has provided a user with access to the telegram groups.";
  }

  if (type === "GroupAccessRealized") {
    return "A user has realized the group access that was given to him by the bot.";
  }

  if (type === "GroupAccessDenied") {
    return "A user has been denied access to the group.";
  }

  if (type === "NewChatMember") {
    return "A user has joined a group";
  }

  return "";
}
