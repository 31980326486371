import "./Header.css";
import logo from "./gecko-logo.webp";
import { Link, Outlet } from "react-router-dom";

function Header() {
  const navigation = [
    { name: "Dashboard", href: "/" },
    { name: "Groups", href: "/groups" },
    { name: "Contacts", href: "/contacts" },
    { name: "Events", href: "/events" },
    { name: "Bot", href: "/bot" },
  ];

  return (
    <header className="bg-indigo-600">
      <nav className="mx-auto max-w-7xl px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-center justify-between border-b border-indigo-500 py-6 lg:border-none">
          <div className="flex items-center">
            <Link to="/">
              <span className="sr-only">FightGecko</span>
              <img className="h-10 w-auto rounded-xl" src={logo} alt="" />
            </Link>
            <div className="ml-10 hidden space-x-8 lg:block">
              {navigation.map((link) => (
                <Link
                  key={link.name}
                  to={link.href}
                  className="text-base font-medium text-white hover:text-indigo-50"
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="ml-10 space-x-4 flex flex-col justify-center">
            <Link
              to="/login"
              className="inline-block rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white hover:bg-opacity-75"
            >
              Sign out
            </Link>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-x-6 py-4 lg:hidden">
          {navigation.map((link) => (
            <a
              key={link.name}
              href={link.href}
              className="text-base font-medium text-white hover:text-indigo-50"
            >
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
}

export default Header;
