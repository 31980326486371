import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../api";
import CrudDetail from "../../Components/CrudDetail";
import Input from "../../Components/Input";
import PageContainer from "../../Layout/PageContainer";
import { TelegramGroup } from "../../Types/TelegramGroup";

function TelegramGroupAccessCard(group: TelegramGroup) {
  if (!group.TelegramGroupAccess) {
    return <></>;
  }
  const totalProvided = group.TelegramGroupAccess.filter(
    (e) => e.accessProvided,
  ).length;
  const totalRealized = group.TelegramGroupAccess.filter(
    (e) => e.accessRealized,
  ).length;

  return (
    <div className="flex flex-col gap-3 border border-gray-200 rounded bg-white px-4 py-5 sm:px-6 mt-4 sm:col-span-3">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {group.telegramGroupName}
      </h3>
      <div className="flex flex-row justify-start gap-5 gap-y-5">
        <span>Access Provided To Users:</span>
        <span>{totalProvided}</span>
      </div>
      <div className="flex flex-row justify-start gap-5 gap-y-5">
        <span>Access Realized By Users:</span>
        <span>{totalRealized}</span>
      </div>
    </div>
  );
}

function GroupDetail() {
  const { id } = useParams();

  const [group, setGroup] = useState<TelegramGroup | null | undefined>(null);

  useEffect(() => {
    async function populate() {
      if (!id) {
        setGroup(undefined);
        return;
      }

      const g = await ApiService.getInstance().getTelegramGroupById(
        parseInt(id!),
      );

      if (!g) {
        setGroup(undefined);
        return;
      }

      setGroup(g.data);
    }
    populate();
  }, [id]);

  if (group === null) {
    return (
      <PageContainer title="Telegram Group">
        <h3 className="text-center m-2">Loading...</h3>
      </PageContainer>
    );
  }

  if (group === undefined) {
    return (
      <PageContainer title="Telegram Group">
        <h3 className="text-center m-2">Failed to fetch data</h3>
      </PageContainer>
    );
  }

  return (
    <PageContainer title={group?.telegramGroupName}>
      <CrudDetail
        title="Group Information"
        subTitle="This information was manually input and cannot be changed."
        redTitle="Please make sure that the telegram bot is an ADMIN inside of this group!"
        onSubmit={() => {}}
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <Input
            name="groupName"
            readonly
            type="text"
            label="Group Name"
            containerClass="sm:col-span-3"
            value={group.telegramGroupName}
          />
          <Input
            name="groupId"
            type="number"
            readonly
            label="Group Id"
            containerClass="sm:col-span-3"
            value={group.telegramGroupId}
          />
        </div>
      </CrudDetail>
      <div className="mt-10 border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Telegram Group Access
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600"></p>
        {TelegramGroupAccessCard(group)}
      </div>
    </PageContainer>
  );
}

export default GroupDetail;
