import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ApiService from "../api";
import CrudDetail from "../Components/CrudDetail";
import Input from "../Components/Input";
import Logo from "../Components/Logo";

export default function Login() {
  const { handleSubmit, register } = useForm();
  const [errors, setErrors] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const onSubmit = async (data: any) => {
    const loginResp = await ApiService.getInstance().login(
      data.email,
      data.password,
    );

    if (loginResp === null || loginResp === undefined) {
      setErrors(["Invalid credentials. Check your E-Mail and Password."]);
      return;
    }

    const token = loginResp.token;
    localStorage.setItem("token", token);
    navigate("/");
  };

  return (
    <div className="h-screen w-screen flex justify-start p-2 items-center self-center flex-col pt-14">
      <Logo />
      <CrudDetail
        title="FightGecko Telegram Community Management"
        containerCssClass="border border-black m-2 p-4 rounded sm:w-2/4 xl:w-1/4 mt-10"
        errors={errors}
        submitText="Login"
        subTitle="Login"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="email"
          type="email"
          label="E-Mail"
          register={register}
          validation={{ required: true }}
        />
        <Input
          name="password"
          type="password"
          label="Password"
          register={register}
          validation={{ required: true }}
        />
      </CrudDetail>
    </div>
  );
}
