import { RegisterOptions, UseFormRegister } from "react-hook-form";

type InputProps = {
  label: string;
  name: string;
  value?: string;
  register?: UseFormRegister<any>;
  validation?: RegisterOptions;
  containerClass?: string;
};

export default function Textarea({
  label,
  name,
  register,
  validation,
  containerClass,
  value,
}: InputProps) {
  return (
    <div className={containerClass}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <textarea
          rows={5}
          className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          id={name}
          defaultValue={value}
          {...(register !== undefined && { ...register(name, validation) })}
        ></textarea>
      </div>
    </div>
  );
}
