import CrudContainerWithSearchbar from "../../Components/CrudContainer";
import PageContainer from "../../Layout/PageContainer";
import { Event, EventIndexResponse } from "../../Types/Event";
import { useCallback, useEffect, useState } from "react";
import CrudList, { CrudListItem } from "../../Components/CrudList";
import ApiService from "../../api";
import Pagination from "../../Components/Pagination";

function Events() {
  const [eventsIndexResponse, setEventsIndexResponse] =
    useState<EventIndexResponse | null>(null);
  const [allEvents, setAllEvents] = useState<Event[] | null | undefined>(null);
  const [filteredEvents, setFilteredEvents] = useState<Event[] | null>(null);

  const filter = (key: string) => {
    if (!allEvents) {
      return;
    }
    const filtered = allEvents.filter((e) =>
      e.type.toLowerCase().includes(key.toLowerCase()),
    );
    setFilteredEvents(filtered);
  };

  const populate = async (skip: number = 0, take: number = 10) => {
    const events = await ApiService.getInstance().getAllEvents(skip, take);
    if (!events) {
      setAllEvents(undefined);
      return;
    }

    setAllEvents(events.data.events);
    setFilteredEvents(events.data.events);
    setEventsIndexResponse(events.data);
  };

  const memoizedPopulate = useCallback(populate, []);

  useEffect(() => {
    memoizedPopulate(0, 20);
  }, [memoizedPopulate]);

  return (
    <PageContainer title="Events">
      <CrudContainerWithSearchbar
        searchCallback={filter}
        searchInputName="searchEventByType"
        searchHint="Search event by type"
        failedToFetch={allEvents === undefined}
        loading={allEvents === null}
        noDataFound={
          filteredEvents !== null &&
          filteredEvents !== undefined &&
          filteredEvents.length <= 0
        }
      >
        {filteredEvents && filteredEvents.length > 0 && (
          <CrudList>
            {filteredEvents.map((event) => (
              <CrudListItem
                key={event.id}
                itemDetailsLink={`/events/${event.id}`}
                itemName={event.type}
                itemSubInfo={`${event.timestamp}${` - ${
                  event.customer?.email ?? ""
                }`}`}
              />
            ))}
          </CrudList>
        )}
      </CrudContainerWithSearchbar>
      <Pagination
        skip={eventsIndexResponse?.skip ?? 0}
        take={eventsIndexResponse?.take ?? 0}
        total={eventsIndexResponse?.total ?? 0}
        populateCallback={populate}
      />
    </PageContainer>
  );
}

export default Events;
