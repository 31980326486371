import { Link, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import PageContainer from "../../Layout/PageContainer";
import { Customer } from "../../Types/Customer";
import { TelegramGroupAccess } from "../../Types/TelegramGroupAccess";
import ApiService from "../../api";
import { useForm } from "react-hook-form";
import CrudDetail from "../../Components/CrudDetail";
import Input from "../../Components/Input";
import Textarea from "../../Components/Textarea";
import { Event } from "../../Types/Event";

const booleanDisplay = (value: boolean) => {
  return (
    <div className={`text-center ${value ? "text-green-500" : "text-red-500"}`}>
      {value ? "✓" : "X"}
    </div>
  );
};

type CardProps = {
  access: TelegramGroupAccess;
  update: () => void;
};

type EventListProps = {
  event: Event;
};

function EventEntry({ event }: EventListProps) {
  return (
    <div className="flex flex-col gap-3 border border-gray-200 rounded bg-white px-4 py-5 sm:px-6 mt-4 sm:col-span-3">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {event.type}
      </h3>
      <Link
        to={`/events/${event.id}`}
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Go to Event
      </Link>
    </div>
  );
}

function TelegramGroupAccessCard({ access, update }: CardProps) {
  const { handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    const updated = access;
    updated.accessRealized = false;
    updated.accessProvided = false;

    await ApiService.getInstance().updateTelegramGroupAccess(updated);
    update();
  };

  return (
    <div className="flex flex-col gap-3 border border-gray-200 rounded bg-white px-4 py-5 sm:px-6 mt-4 sm:col-span-3">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {access.telegramGroup!.telegramGroupName}
      </h3>
      <div className="flex flex-row justify-start gap-5 gap-y-5">
        <span>Access Provided To User:</span>
        {booleanDisplay(access.accessProvided)}
      </div>
      <div className="flex flex-row justify-start gap-5 gap-y-5">
        <span>Access Realized By User:</span>
        {booleanDisplay(access.accessRealized)}
      </div>
      <div className="flex flex-row justify-start gap-5 gap-y-5">
        <span>(Latest) Invite Link:</span>
        <span>{access.inviteLink}</span>
      </div>
      {access.accessProvided && (
        <form
          className="flex flex-row justify-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <button className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Reset Access For User
          </button>
        </form>
      )}
    </div>
  );
}

function ContactDetail() {
  const { id } = useParams();
  const [customer, setCustomer] = useState<Customer | null | undefined>(null);

  const populate = async () => {
    if (!id) {
      setCustomer(undefined);
      return;
    }

    const customer = await ApiService.getInstance().getCustomerById(
      parseInt(id),
    );

    if (!customer) {
      setCustomer(undefined);
      return;
    }
    setCustomer(customer.data);
  };

  const memoizedPopulate = useCallback(populate, [id]);

  useEffect(() => {
    async function run() {
      await memoizedPopulate();
    }
    run();
  }, [id, memoizedPopulate]);

  return (
    <PageContainer title={`${customer?.firstName} ${customer?.lastName}`}>
      <CrudDetail
        title="Contact from Stripe with Telegram Data"
        loading={customer === null}
        asDiv={true}
        failedFetch={customer === undefined}
        subTitle=""
        onSubmit={() => {}}
      >
        <CrudDetail
          title="Contact Information"
          subTitle={`This information was extracted from ${customer?.firstName} using Telegram and Stripe and cannot be changed.`}
          onSubmit={() => {}}
        >
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <Input
              name="firstName"
              type="text"
              label="First Name"
              value={customer?.firstName}
              readonly
              containerClass="sm:col-span-3"
            />
            <Input
              name="lastName"
              type="text"
              label="Last Name"
              value={customer?.lastName}
              readonly
              containerClass="sm:col-span-3"
            />
          </div>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <Input
              name="email"
              type="email"
              label="E-Mail"
              value={customer?.email}
              readonly
              containerClass="sm:col-span-3"
            />
            <Input
              name="stripeFullName"
              type="text"
              label="Stripe Customer Name"
              value={customer?.stripeFullName}
              readonly
              containerClass="sm:col-span-3"
            />
          </div>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <Textarea
              name="aspirations"
              label="Aspirations / Goals"
              value={customer?.aspirations}
              containerClass="sm:col-span-3"
            />
            <Input
              name="stripeCustomerId"
              type="text"
              label="Stripe Customer Id"
              value={customer?.stripeCustomerId}
              containerClass="sm:col-span-3"
              readonly
            />
          </div>
        </CrudDetail>

        <CrudDetail
          containerCssClass="mt-10"
          title="Telegram Information"
          subTitle={`This information was extracted from ${customer?.firstName} using Telegram and cannot be changed.`}
          onSubmit={() => {}}
        >
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <Input
              value={customer?.telegramUserId}
              containerClass="sm:col-span-3"
              label="Telegram User Id"
              name="telegramUserId"
              readonly
              type="text"
            />
            <Input
              value={customer?.telegramChatId}
              readonly
              containerClass="sm:col-span-3"
              label="Telegram Chat Id"
              name="telegramChatId"
              type="text"
            />
            <Input
              value={customer?.telegramUserHandle}
              readonly
              containerClass="sm:col-span-3"
              label="Telegram User Handle"
              name="telegramUserHandle"
              type="text"
            />
          </div>
        </CrudDetail>

        <CrudDetail
          containerCssClass="mt-10"
          title="Telegram Group Access"
          asDiv
          subTitle={`Telegram Groups that ${customer?.firstName} has access to. Here you can reset his access if any problems occur or see details.`}
          onSubmit={() => {}}
        >
          {customer?.TelegramGroupAccess && (
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-x-6">
              {customer.TelegramGroupAccess?.map((e) => {
                return (
                  <TelegramGroupAccessCard
                    key={`${e.customerId}_${e.telegramGroupId}`}
                    access={e}
                    update={populate}
                  />
                );
              })}
            </div>
          )}
        </CrudDetail>

        {customer?.Event && customer.Event.length > 0 && (
          <CrudDetail
            containerCssClass="mt-10"
            title="Event List"
            asDiv
            subTitle={`Events that have occurred with this contact. Some events (like CommandExecuted) are not listed`}
            onSubmit={() => {}}
          >
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-x-6">
              {customer?.Event.map((e) => {
                return <EventEntry key={e.id} event={e} />;
              })}
            </div>
          </CrudDetail>
        )}
      </CrudDetail>
    </PageContainer>
  );
}

export default ContactDetail;
