import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../Layout/PageContainer";
import ApiService from "../../api";
import CrudDetail from "../../Components/CrudDetail";
import Input from "../../Components/Input";
import Textarea from "../../Components/Textarea";

function CommandCreate() {
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    const outputType = data.outputType;
    const command = data.command;
    const commandOutput = data.commandOutput;

    const createResp = await ApiService.getInstance().createTelegramBotCommand(
      command,
      outputType,
      commandOutput,
    );

    if (!createResp) {
      setErrors(["Unkown errors, please retry"]);
      return;
    }

    if (createResp.message && Array.isArray(createResp.message)) {
      setErrors(createResp.message);
      return;
    }

    if ("data" in createResp) {
      navigate(`/commands/${createResp.data.id}`);
    }
  };

  const [errors, setErrors] = useState<string[]>([]);

  return (
    <PageContainer title="Create new command">
      <CrudDetail
        title="Command Information"
        submitText="Create"
        subTitle="This command can be used by users in the telegram bot"
        onSubmit={handleSubmit(onSubmit)}
        errors={errors}
      >
        <div className="grid gap-y-8">
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-x-6 gap-y-8">
            <Input
              containerClass="sm:col-span-3"
              register={register}
              name="command"
              type="text"
              label="Command"
              validation={{ required: true }}
            />
            <Input
              containerClass="sm:col-span-3"
              register={register}
              name="outputType"
              type="text"
              label="Output Type"
              validation={{ required: true }}
            />
          </div>
          <Textarea
            validation={{ required: true }}
            label="Command Output"
            name="commandOutput"
            register={register}
          />
        </div>
      </CrudDetail>
    </PageContainer>
  );
}

export default CommandCreate;
